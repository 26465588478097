<template>
    <div class="contain" v-infinite-scroll="load" :infinite-scroll-disabled="finish">
        <searchBar @search="search"></searchBar>
        <div class="title bgf flex">
            <div class="fs_18 bold color_333 flex_start">
                <span>{{ title }}</span>
            </div>
            <!-- <router-link to="/position/post" class="btn flex_cen">
                <img src="@/assets/imgs/icon/post-icon.png" class="post-icon" alt="" />
                <span class="fs_14 color">发布职位</span>
            </router-link> -->
        </div>
        <div class="subtitle flex">
            <div class="flex_c">
                <div class="button color_999 fs_14 flex_cen btn mr_10 active">
                    <span class="center">已投递 ({{ data.apply_num || 0 }})</span>
                </div>
                <div @click="$router.replace(`/position/invite/${id}/${title}`)" replace class="button color_999 fs_14 flex_cen btn mr_10">
                    <span class="center">已邀请面试 ({{ data.interview_num || 0 }})</span>
                </div>
                <div @click="$router.replace(`/position/entry/${id}/${title}`)" class="button color_999 fs_14 flex_cen btn mr_10">
                    <span class="center">已入职 ({{ data.join_num || 0 }})</span>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="item flex bottom_link" v-for="(item, index) in list" :key="index" @click.stop="detail(item.user_id, item.id)">
                <el-avatar shape="circle" :size="80" :fit="'contain'" :src="item.avatar" class="mr_20">
                    <img :src="item.gender == '男' ? $store.state.nanAvatar : $store.state.nvAvatar" />
                </el-avatar>
                <div class="flex_1 flex pointer">
                    <div class="userinfo">
                        <div class="fs_16 color_333 bold">{{ item.realname }}</div>
                        <p class="flex_c fs_14 color_333 use_tip mt_10">
                            <span class="label">基本信息：</span>
                            <span class="line" v-if="item.current_city">
                                {{ item.current_city }}
                            </span>
                            <span class="line" v-if="item.gender">{{ item.gender }}</span>
                            <span class="line" v-if="item.age">{{ item.age }}</span>
                            <span class="line" v-if="item.work_exp">{{ item.work_exp }}</span>
                            <span class="line" v-if="item.education">
                                {{ item.education }}
                            </span>
                        </p>
                        <p class="flex_c fs_14 color_333 use_tip mt_5" v-if="item.labels.length > 0">
                            <span class="label">求职关注点：</span>
                            <span v-if="item.labels">
                                <span class="line" v-for="(s, i) in item.labels" :key="i">{{
									s
								}}</span>
                            </span>
                            <span v-else>暂无</span>
                        </p>
                        <p class="flex_c fs_14 color_333 use_tip mt_5">
                            <span class="label">最近工作经历：</span>
                            <span v-if="item.last_work_exp">
                                <span class="line">{{ item.last_work_exp.company_name }}</span>
                                <span class="line">{{ item.last_work_exp.job_name }}</span>
                                <span class="line">{{ item.last_work_exp.start_time }}至{{
										item.last_work_exp.end_time
									}}</span>
                            </span>
                            <span v-else>暂无</span>
                        </p>
                    </div>
                    <div class="job center">
                        <p class="fs_18 bold">{{ item.job || "-" }}</p>
                        <p class="fs_14 color_999 mt_5">意向职位</p>
                    </div>
                    <div class="mobile center">
                        <p class="fs_18 bold">{{ item.mobile }}</p>
                        <div class="btn flex_cen msg" @click.stop="invited(item.id)">
                            <img src="@/assets/imgs/icon/msg-icon.png" class="icon" alt="" />
                            <p class="fs_14">邀请面试</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <empty :finish="finish" :length="list.length"></empty>

        <resumeInfo ref="resumeInfo"></resumeInfo>
        <invited ref="invited"></invited>
    </div>
</template>

<script>
import resumeInfo from "../talents-hall/dialog/resume-info.vue"
import invited from "../talents-hall/dialog/invited.vue"
export default {
	components: { resumeInfo, invited },
	name: "Collection",
	data() {
		return {
			name: "collection",
			keywords: "",
			id: "",
			title: "",
			list: [],
			limit: 20,
			page: 1,
			finish: false,
			data: {
				apply_num: 0,
				interview_num: 0,
				join_num: 0
			},
			type: "intention"
		}
	},
	created() {
		this.id = this.$route.params.id
		this.title = this.$route.params.title
		this.getTabNum()
		this.getList()
	},
	methods: {
		//子节点
		childInit() {
			this.getTabNum()
			this.page = 1
			this.list = []
			this.getList()
		},
		load() {
			this.page++
			this.getList()
		},
		getTabNum() {
			this.$axios({
				url: `/ent/v3/job/tab_num/${this.id}`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					this.data = res.data
				}
			})
		},
		getList() {
			this.$axios({
				url: `/ent/v3/job/apply_list/${this.id}`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					keywords: this.keywords
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = this.list.concat(res.data.list)
					this.finish = this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		search(v) {
			this.keywords = v
			this.page = 1
			this.list = []
			this.finish = false
			this.getList()
		},
		detail(id, job_id) {
			this.$axios({
				url: `/ent/v3/talent/view_user/${id}`,
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					res.data.invited_type = this.type
					res.data.job_id = job_id
					this.handleDialog("resumeInfo", res.data, id)
				}
			})
		},
		handleDialog(dialog, data, id) {
			this.$refs[dialog].dialogVisible = true
			this.$refs[dialog].data = data
			this.$refs[dialog].id = id
			// this.$refs[dialog].init()
		},
		invited(id) {
			this.$refs.invited.id = id
			this.$refs.invited.form.type = this.type
			this.$refs.invited.init()
			this.$refs.invited.dialogVisible = true
		}
	}
}
</script>

<style lang="less" scoped>
.subtitle {
    .button {
        width: 130px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 25px;
        &.active {
            border-color: #1890ff;
            color: #1890ff;
        }
    }
}
.title {
    padding: 18px 40px 18px 40px;
    margin-bottom: 10px;

    .post-icon {
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
}
.list {
    margin-top: 10px;
    background-color: #fff;
    .item {
        height: 160px;
        padding: 20px 90px 20px 30px;
        transition: all 0.2s ease;
        &:hover {
            background-color: #f8f8f8;
            transition: all 0.2s ease;
        }
        .avatar {
            width: 120px;
            height: 120px;
            border-radius: 100%;
            margin-right: 20px;
        }
        .userinfo {
            .label {
                width: 100px;
                font-size: 14px;
                color: #999;
            }
        }
        .mobile {
            .btn {
                width: 86px;
                height: 22px;
                border-radius: 2px;
                margin: 6px auto 0;
                &.msg {
                    border: #1890ff 1px solid;
                    color: #1890ff;
                }
                &.tel {
                    border: #f8b33c 1px solid;
                    color: #f8b33c;
                }
                .icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>
